._2BaVdEFFW73PEOLhPV9i4i {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 99999999;
}

._1o2XwTXJU6iXHBLm6NsThh {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

._1XssPterjwf-ChkbnLoTDv {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

._1DLBGo68tTYhza70VwW8Az {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

._3YE1H08CAmYvZbffFUR_fr {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
