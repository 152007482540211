._1PicEea8rQpVhiBJxFnvDQ {
  font-size: 1em;
}
._2vY-DnT5GLxF5wX-PtnlF2 {
  padding-top: 0.8em;
}
._3rC_cjkSKh3AI-pxxTMKsI {
  margin: 0.4em 1em;
}
._3bp7E00ZlAcPnVKqpgLail,
.v15Wi5dO6SWz1WBnWARHM {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
._39oajklfRUXNjZ3A0htTTW {
  font-size: 1em;
}
._3Wgm5u1YO7plFRlmq2DKS5 {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
._1VBqMko1UvOivNN84JcvpF {
  border-right-color: #ccc;
  left: 1em;
}
._2eYWC3D6mAbKI9BYkT-hnn {
  border-left-color: #ccc;
  right: 1em;
}
