._3k7ktxebdkqbK0mFzXVx0_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 0, 0, 0.327);
}

._14kmTrlX2N16ZT8oV1cF3B {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
