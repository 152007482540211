._2Iam0EsU8zSG6fNxLPipp5 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

._2JImPHANrzlzuZzpkLDQiQ {
  /* border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%; */
  width: 100%;
  max-width: 50px;
  aspect-ratio: 1/1;
  animation: _3vWSk9nx061g_oAvw7Ko_t 0.6s steps(8) infinite;
}

@keyframes _3vWSk9nx061g_oAvw7Ko_t {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
