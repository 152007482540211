.N4Ugt5jPfa5NJt7v2j6Qn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

._2b0SeB95mH61dR7A38QAF5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

._2H6hekuH77VC5XvtpBUhMJ {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

._2H6hekuH77VC5XvtpBUhMJ input {
  min-height: 30px;
}

._3kzJUO1r89YIbHGlB-oz8H {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._3RUE-XkpOH76uZF0g1x9aQ {
  width: 100% !important;
  height: 40px !important;
  outline: none !important;
  margin: 0px !important;
  padding: 8px !important;
  min-width: 0px !important;
  border: 1px solid #eaeaea !important;
  background-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  transition: all 0.2s ease;
  flex: 1;
  border-radius: 2px;
}

._3RUE-XkpOH76uZF0g1x9aQ:hover {
  border-color: #5cb85c !important;
}

._3RUE-XkpOH76uZF0g1x9aQ:focus {
  border-color: #5cb85c !important;
  background-color: #fff;
}

.uULlDybT8aS2CmatFskBd {
  background-color: #f8f8f8;
  color: #b3b3b3;
  aspect-ratio: 1.5 / 1;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.uULlDybT8aS2CmatFskBd::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23b3b3b3' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 1a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2H9v5a1 1 0 0 1-2 0V9H2a1 1 0 0 1 0-2h5V2a1 1 0 0 1 1-1z'/%3E%3C/svg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #e7e7e7;
  border-radius: 4px;
}

.gPU_onbqKlfaDo1PlcATM {
  font-size: 14px;
  font-family: inherit;
  aspect-ratio: auto;
  padding: 6px 12px;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  color: #000000;
  font-weight: 400;
}

.gPU_onbqKlfaDo1PlcATM::before {
  display: none;
}
