._1HEnKLsmpetjrCWx2nySmv {
  width: 100%;
  margin: 0 auto;
}

._3eS34eU_P-2reJPiM4Ydsn {
  transform: translate(0px, 200px);
  -ms-transform: translate(0px, 200px); /* IE 9 */
  -webkit-transform: translate(0px, 200px); /* Safari and Chrome */
  -o-transform: translate(0px, 200px); /* Opera */
  -moz-transform: translate(0px, 200px); /* Firefox */
}

.tKRLE_7pMZWnidXEGQxES {
  text-align: center;
}
